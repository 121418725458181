import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Paper, Typography, Grid, Container, Button, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DraftsIcon from '@material-ui/icons/Drafts';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image
import Homebg from "../images/homebg.jpg"
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import styled from "styled-components";

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Bline = styled.hr`
width: 70px;
margin-bottom:15px;
border-color: #1c6a9c;
`

const Homeback = {
  backgroundAttachment: 'fixed',
  backgroundImage: 'url('+ Homebg+')'
  }

export default function Contacts(){

  const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState('');
    const [submitname, setSubmitname] = React.useState('Submit');

  
    const handleChange = (event) =>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if(name==="name")
    {
      setName(value);
    }
    if(name==="email")
    {
      setEmail(value);
    }
    
    if(name==="message")
    {
      setMessage(value);
    }

    if(name==="mobile")
    {
      setMobile(value);
    }
    
  }
 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
     setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitname('Submitting... ')
    let collection={};
      collection.name = name;
      collection.email = email;
      collection.message = message;
      collection.mobile = mobile;
      collection.scode = "sdferderv3876MBg";

   fetch('https://apiv2.chennaihost.com/site/contact', {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

        //make sure to serialize your JSON body
        body: JSON.stringify(collection),
        })
        .then( (response) => { 
          return response.json();
        })
        .then((data) => {
          if(data)
          {
            setResult(data.result);
            setName('');
            setEmail('');
            setMessage('');
            setMobile('');
            setOpen(true);
            setSubmitname('submit');
         
          }
       }
        )
   


  };

return(
  <Layout>
    <Seo title="Computed Radiography - Color Doppler" description="Contact details, Phone Numbers and Emails" />
    <ThemeProvider theme={theme}>
    <Aboutback title="Contacts" />
{/* address starts */}
<Box bgcolor="#fff">
  <Container>
    <Box pt={3} pb={3}>
  <Grid container>
   <Grid item md={4}>
<Box p={2} display="flex" borderRight={1}>
<Box pr={1}>
<RoomIcon style={{ color:'#fd0100', fontSize:50 }} />
</Box>
<Box>
        <Typography  gutterBottom variant="body1">
        AFRICORP HEALTHCARE SYSTEMS LTD
        </Typography>
        <Typography  gutterBottom variant="h6">
        Lusaka - 10101, Zambia
        </Typography>
        </Box>

</Box>
   </Grid>

   <Grid item md={4}>
<Box p={2} display="flex" borderRight={1}>
<Box pr={2}>
<PhoneInTalkIcon style={{ color:'#fd0100', fontSize:45 }} />
</Box>
<Box>
        <Typography  gutterBottom variant="body1">
        CALL US
        </Typography>
        <Typography  gutterBottom variant="h6">
        <a href="tel:+260953020081" target="_blank" rel="noopener noreferrer" style={{ color:'#000' }}>+260 953 02 0081</a> 
        </Typography>
        </Box>

</Box>
   </Grid>

   <Grid item md={4}>
<Box p={2} display="flex">
<Box pr={2}>
<DraftsIcon style={{ color:'#fd0100', fontSize:45 }} />
</Box>
<Box>
        <Typography  gutterBottom variant="body1">
        E-Mail
        </Typography>
        <Typography  gutterBottom variant="h6">
        <a href="mailto:info@africorp-healthcare.com" target="_blank" rel="noopener noreferrer" style={{ color:'#000' }}>info@africorp-healthcare.com</a>
        </Typography>
        </Box>

</Box>
   </Grid>

   
     </Grid>
     </Box>
  </Container>
</Box>
   {/* form starts here */}
   <div style={Homeback}>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
<Box pt={4} pb={4}>
   <Container>
     <Grid container justifyContent="center">
       <Grid item xs={12} md={8}>
         <Paper elevation={3}>
           <Box p={4}>
     
       <Typography gutterBottom variant="h4" align="center">
             Write a Message
             </Typography>
<Bline/>
<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           {result}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>
<ValidatorForm onSubmit={handleSubmit}  autoComplete="off">
<TextValidator 
          id="name"
          name="name"
          label="Your Name" 
          value={name}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

<TextValidator 
          id="mobile"
          name="mobile"
          label="Mobile" 
          value={mobile}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="email"
          name="email"
          label="Email" 
          value={email}
          onChange={handleChange}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="message"
          name="message"
          label="Message" 
          value={message}
          onChange={handleChange}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />
          
           

        <Button variant="contained" color='primary' size="large" type="submit">{submitname}</Button>
         </ValidatorForm>
  

  
    </Box>
    </Paper>

       </Grid>

     </Grid>
   </Container>
   </Box>
  </div>
  </div>


</ThemeProvider>
  </Layout>
);
}


